const FR = {
  ZOOM_IN: 'Agrandir affichage',
  ZOOM_OUT: 'Réduire affichage',
  TOPIC_SHAPE: 'Forme du noeud',
  TOPIC_ADD: 'Ajouter un noeud',
  TOPIC_DELETE: 'Supprimer le noeud',
  TOPIC_ICON: 'Ajouter une icône',
  TOPIC_LINK: 'Ajouter un lien',
  TOPIC_RELATIONSHIP: 'Relation du noeud',
  TOPIC_COLOR: 'Couleur du noeud',
  TOPIC_BORDER_COLOR: 'Couleur de bordure du noeud',
  TOPIC_NOTE: 'Ajouter une note',
  FONT_FAMILY: 'Type de police',
  FONT_SIZE: 'Taille de police',
  FONT_BOLD: 'Caractères gras',
  FONT_ITALIC: 'Caractères italiques',
  UNDO: 'Annuler',
  REDO: 'Refaire',
  INSERT: 'Insérer',
  SAVE: 'Enregistrer',
  NOTE: 'Note',
  ADD_TOPIC: 'Ajouter un noeud',
  LOADING: 'Chargement ...',
  EXPORT: 'Exporter',
  PRINT: 'Imprimer',
  PUBLISH: 'Publier',
  COLLABORATE: 'Partager',
  HISTORY: 'Historique',
  DISCARD_CHANGES: 'Annuler les changements',
  FONT_COLOR: 'Couleur de police',
  SAVING: 'Enregistrement ...',
  SAVE_COMPLETE: 'Enregistrement terminé',
  ZOOM_IN_ERROR: 'Zoom trop grand.',
  ZOOM_ERROR: 'Impossible de zoomer plus.',
  ONLY_ONE_TOPIC_MUST_BE_SELECTED: 'Impossible de créer un noeud. Un seul noeud doit être sélectionné.',
  ONE_TOPIC_MUST_BE_SELECTED: 'Impossible de créer un noeud. Un noeud parent doit être sélectionné au préalable.',
  ONLY_ONE_TOPIC_MUST_BE_SELECTED_COLLAPSE: 'Un noeud enfant ne peut pas être réduit. Un noeud doit être sélectionné.',
  SAVE_COULD_NOT_BE_COMPLETED: 'Enregistrement impossible. Essayer ultérieurement.',
  UNEXPECTED_ERROR_LOADING: 'Nous sommes désolés, une erreur vient de survenir.\nEssayez de recharger l\'éditeur. Si le problème persiste, contactez-nous support@wisemapping.com.',
  MAIN_TOPIC: 'Noeud titre principal',
  SUB_TOPIC: 'Noeud sous-titre',
  ISOLATED_TOPIC: 'Noeud isolé',
  CENTRAL_TOPIC: 'Noeud racine',
  SHORTCUTS: 'Raccourcis clavier',
  ENTITIES_COULD_NOT_BE_DELETED: "Impossible d'effacer un noeud ou une relation. Au moins un objet de la carte doit être sélectionné.",
  AT_LEAST_ONE_TOPIC_MUST_BE_SELECTED: 'Au moins un objet de la carte doit être sélectionné.',
  CLIPBOARD_IS_EMPTY: 'Rien à copier. Presse-papier vide.',
  CENTRAL_TOPIC_CAN_NOT_BE_DELETED: 'Le noeud racine ne peut pas être effacé.',
  RELATIONSHIP_COULD_NOT_BE_CREATED: 'Impossible de créer relation. Un noeud parent doit être sélectionné au préalable.',
  SELECTION_COPIED_TO_CLIPBOARD: 'Noeuds sélectionnés copiés dans le presse-papiers.',
  WRITE_YOUR_TEXT_HERE: 'Écrivez votre texte ici ...',
  REMOVE: 'Supprimer',
  ACCEPT: 'Accepter',
  CANCEL: 'Annuler',
  LINK: 'Lien',
  OPEN_LINK: 'Ouvrir le lien',
  SESSION_EXPIRED: 'Votre session a expiré, veuillez vous reconnecter.',
  URL_ERROR: 'URL non valide',
  ACTION: 'Action',
  CREATE_SIBLING_TOPIC: 'Créer noeud même niveau',
  CREATE_CHILD_TOPIC: 'Créer noeud enfant',
  DELETE_TOPIC: 'Détruire noeud ',
  EDIT_TOPIC_TEXT: 'Editer texte du noeud',
  JUST_START_TYPING: 'Commencer saisie',
  CANCEL_TEXT_CHANGES: 'Annuler changement texte',
  TOPIC_NAVIGATION: 'Navigation sur les noeuds',
  ARROW_KEYS: 'Touches flèches',
  SELECT_MULTIPLE_NODES: 'Selection multiple de noeuds',
  UNDO_EDITION: 'Annuler édition',
  REDO_EDITION: 'Refaire édition',
  SELECT_ALL_TOPIC: 'Sélection tous noeuds',
  CHANGE_TEXT_BOLD: 'Caractères en gras',
  SAVE_CHANGES: 'Enregistrer changements',
  CHANGE_TEXT_ITALIC: 'Caractères en italique',
  DESELECT_ALL_TOPIC: 'Deselection tous noeuds',
  COLLAPSE_CHILDREN: 'Fermer enfants',
  KEYBOARD_SHORTCUTS_MSG: 'Les raccourcis clavier vous font gagner du temps, en vous permettant de garder les mains sur le clavier sans utiliser la souris.',
  COPY_AND_PASTE_TOPICS: 'Copier et coller les noeuds',
  MULTIPLE_LINES: 'Ajouter plusieurs lignes de texte',
  BACK_TO_MAP_LIST: 'Retour à la liste des cartes',
  KEYBOARD_SHOTCUTS: 'Raccourcis clavier',
  PASTE_URL_HERE: 'Collez l\'adresse URL souhaitée ici :',
  CTRL: 'Ctrl',
  SPACE_BAR: 'Space Bar',
  K_INSERT: 'Insert',
  MOUSE_CLICK: 'Clic de Souris',
  K_DELETE: 'Delete',
  BACKSPACE: 'Backspace',
};

export default FR;
