const ES = {
  ZOOM_IN: 'Acercar',
  ZOOM_OUT: 'Alejar',
  TOPIC_SHAPE: 'Forma del Tópico',
  TOPIC_ADD: 'Agregar Tópico',
  TOPIC_DELETE: 'Borrar Tópico',
  TOPIC_ICON: 'Agregar Icono',
  TOPIC_LINK: 'Agregar Enlace',
  TOPIC_RELATIONSHIP: 'Relación',
  TOPIC_COLOR: 'Color Tópico',
  TOPIC_BORDER_COLOR: 'Color del Borde',
  TOPIC_NOTE: 'Agregar Nota',
  FONT_FAMILY: 'Tipo de Fuente',
  FONT_SIZE: 'Tamaño de Texto',
  FONT_BOLD: 'Negrita',
  FONT_ITALIC: 'Italica',
  UNDO: 'Rehacer',
  REDO: 'Deshacer',
  INSERT: 'Insertar',
  SAVE: 'Guardar',
  NOTE: 'Nota',
  ADD_TOPIC: 'Agregar Tópico',
  LOADING: 'Cargando ...',
  EXPORT: 'Exportar',
  PRINT: 'Imprimir',
  PUBLISH: 'Publicar',
  COLLABORATE: 'Compartir',
  HISTORY: 'Historial',
  DISCARD_CHANGES: 'Descartar Cambios',
  FONT_COLOR: 'Color de Texto',
  SAVING: 'Grabando ...',
  SAVE_COMPLETE: 'Grabado Completo',
  ZOOM_IN_ERROR: 'El zoom es muy alto.',
  ZOOM_ERROR: 'No es posible aplicar mas zoom.',
  ONLY_ONE_TOPIC_MUST_BE_SELECTED: 'No ha sido posible crear un nuevo tópico. Sólo un tópico debe ser seleccionado.',
  ONE_TOPIC_MUST_BE_SELECTED: 'No ha sido posible crear un nuevo tópico. Al menos un tópico debe ser seleccionado.',
  ONLY_ONE_TOPIC_MUST_BE_SELECTED_COLLAPSE: 'Tópicos hijos no pueden ser colapsados. Sólo un tópico debe ser seleccionado.',
  SAVE_COULD_NOT_BE_COMPLETED: 'Grabación no pudo ser completada. Intentelo mas tarde.',
  UNEXPECTED_ERROR_LOADING: 'Lo sentimos, un error inesperado ha ocurrido. Intentelo nuevamente recargando el editor. Si el problema persiste, contactenos a support@wisemapping.com.',
  MAIN_TOPIC: 'Tópico Principal',
  SUB_TOPIC: 'Tópico Secundario',
  ISOLATED_TOPIC: 'Tópico Aislado',
  CENTRAL_TOPIC: 'Tópico Central',
  SHORTCUTS: 'Accesos directos',
  ENTITIES_COULD_NOT_BE_DELETED: 'El tópico o la relación no pudo ser borrada. Debe selecionar al menos una.',
  AT_LEAST_ONE_TOPIC_MUST_BE_SELECTED: 'Al menos un tópico debe ser seleccionado.',
  CLIPBOARD_IS_EMPTY: 'Nada que copiar. Clipboard está vacio.',
  CENTRAL_TOPIC_CAN_NOT_BE_DELETED: 'El tópico central no puede ser borrado.',
  RELATIONSHIP_COULD_NOT_BE_CREATED: 'La relación no pudo ser creada. Una relación padre debe ser seleccionada primero.',
  SELECTION_COPIED_TO_CLIPBOARD: 'Tópicos copiados al clipboard',
  WRITE_YOUR_TEXT_HERE: 'Escribe tu nota aquí ...',
  REMOVE: 'Borrar',
  ACCEPT: 'Aceptar',
  CANCEL: 'Cancelar',
  LINK: 'Enlace',
  OPEN_LINK: 'Abrir Enlace',
  SESSION_EXPIRED: 'Su session ha expirado. Por favor, ingrese nuevamente.',
  URL_ERROR: 'URL no válida',
  ACTION: 'Acción',
  CREATE_SIBLING_TOPIC: 'Agregar Tópico Hermano',
  CREATE_CHILD_TOPIC: 'Agregar Tópico Hijo',
  DELETE_TOPIC: 'Borrar Tópico',
  EDIT_TOPIC_TEXT: 'Editar Texto de Tópico',
  JUST_START_TYPING: 'Comenza a escribir',
  CANCEL_TEXT_CHANGES: 'Cancelar Edición de Texto',
  TOPIC_NAVIGATION: 'Navegación Entre Tópicos',
  ARROW_KEYS: 'Flechas Del Cursor',
  SELECT_MULTIPLE_NODES: 'Selecciónar Multiples Tópicos',
  UNDO_EDITION: 'Revertir Cambios',
  REDO_EDITION: 'Rehacer Cambios',
  SELECT_ALL_TOPIC: 'Seleccionar Todos los Tópicos',
  CHANGE_TEXT_BOLD: 'Cambiar Texto a Negrita',
  SAVE_CHANGES: 'Guardar los Cambios',
  CHANGE_TEXT_ITALIC: 'Cambiar Texto a Italica',
  DESELECT_ALL_TOPIC: 'Revertir Selección de Tópicos',
  COLLAPSE_CHILDREN: 'Colapsar Hijos',
  KEYBOARD_SHORTCUTS_MSG: 'Los accesos directos pueden ayudarte a salvar tiempo permitiéndote no sacar las manos del teclado para usar el mouse.',
  COPY_AND_PASTE_TOPICS: 'Copier et coller les noeuds',
  MULTIPLE_LINES: 'Ajouter plusieurs lignes de texte',
  BACK_TO_MAP_LIST: 'Volver a la lista de mapas',
  KEYBOARD_SHOTCUTS: 'Métodos abreviados de teclado',
  PASTE_URL_HERE: 'Copie la URL que desea aca:',
  CTRL: 'Ctrl',
  SPACE_BAR: 'Space Bar',
  K_INSERT: 'Insert',
  MOUSE_CLICK: 'Mouse Click',
  K_DELETE: 'Delete',
  BACKSPACE: 'Backspace',
};

export default ES;
