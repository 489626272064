const DE = {
  ZOOM_IN: 'Ansicht vergrößern',
  ZOOM_OUT: 'Ansicht verkleinern',
  TOPIC_SHAPE: 'Themen Gestaltung',
  TOPIC_ADD: 'Thema hinzufügen',
  TOPIC_DELETE: 'Thema löschen',
  TOPIC_ICON: 'Symbol hinzufügen',
  TOPIC_LINK: 'Verbindung hinzufügen',
  TOPIC_RELATIONSHIP: 'Beziehung',
  TOPIC_COLOR: 'Themenfarbe',
  TOPIC_BORDER_COLOR: 'Thema Randfarbe',
  TOPIC_NOTE: 'Notiz hinzufügen',
  FONT_FAMILY: 'Schriftart',
  FONT_SIZE: 'Schriftgröße',
  FONT_BOLD: 'Fett',
  FONT_ITALIC: 'Kursiv',
  UNDO: 'Rückgängig',
  REDO: 'Wiederholen',
  INSERT: 'Einfügen',
  SAVE: 'Speichern',
  NOTE: 'Notiz',
  ADD_TOPIC: 'Thema hinzufügen',
  LOADING: 'Lädt ...',
  EXPORT: 'Exportieren',
  PRINT: 'Drucken',
  PUBLISH: 'Veröffentlichen',
  COLLABORATE: 'Mitbenutzen',
  HISTORY: 'Historie',
  DISCARD_CHANGES: 'Änderungen verwerfen',
  FONT_COLOR: 'Textfarbe',
  SAVING: 'Speichern ...',
  SAVE_COMPLETE: 'Speichern abgeschlossen',
  ZOOM_IN_ERROR: 'Zoom zu hoch.',
  ZOOM_ERROR: 'Es kann nicht weiter vergrößert bzw. verkleinert werden.',
  ONLY_ONE_TOPIC_MUST_BE_SELECTED: 'Thema konnte nicht angelegt werden. Bitte wählen Sie nur ein Thema aus.',
  ONE_TOPIC_MUST_BE_SELECTED: 'Thema konnte nicht angelegt werden. Es muss ein Thema ausgewählt werden.',
  ONLY_ONE_TOPIC_MUST_BE_SELECTED_COLLAPSE: 'Kinderknoten können nicht eingefaltet werden. Es muss ein Thema ausgewäht werden.',
  SAVE_COULD_NOT_BE_COMPLETED: 'Sichern wurde nicht abgeschlossen. Versuchen Sie es später nocheinmal.',
  UNEXPECTED_ERROR_LOADING: 'E tut uns Leid, ein unerwarteter Fehler ist aufgetreten.\nVersuchen Sie, den Editor neu zu laden. Falls das Problem erneut auftritt, bitte kontaktieren Sie uns unter support@wisemapping.com.',
  MAIN_TOPIC: 'Hauptthema',
  SUB_TOPIC: 'Unterthema',
  ISOLATED_TOPIC: 'Isoliertes Thema',
  CENTRAL_TOPIC: 'Zentrales Thema',
  SHORTCUTS: 'Tastenkombinationen',
  ENTITIES_COULD_NOT_BE_DELETED: 'Konnte das Thema oder die Beziehung nicht löschen. Es muss mindest ein Eintrag ausgewählt sein.',
  AT_LEAST_ONE_TOPIC_MUST_BE_SELECTED: 'Es muss mindestens ein Thema ausgewählt sein.',
  CLIPBOARD_IS_EMPTY: 'Es gibt nichts zu kopieren. Die Zwischenablage ist leer.',
  CENTRAL_TOPIC_CAN_NOT_BE_DELETED: 'Das zentrale Thema kann nicht gelöscht werden.',
  RELATIONSHIP_COULD_NOT_BE_CREATED: 'Die Beziehung konnte nicht angelegt werden. Es muss erst ein Vater-Thema ausgewählt werden, um die Beziehung herzustellen.',
  SELECTION_COPIED_TO_CLIPBOARD: 'Themen in der Zwischenablage',
  WRITE_YOUR_TEXT_HERE: 'Schreibe deine Notiz hier ...',
  REMOVE: 'Entfernen',
  ACCEPT: 'Akzeptieren',
  CANCEL: 'Abbrechen',
  LINK: 'Verbindung',
  OPEN_LINK: 'Öffne URL',
  SESSION_EXPIRED: 'Deine Sitzung ist abgelaufen, bitte melde dich erneut an.',
  URL_ERROR: 'URL nicht gültig',
  ACTION: 'Aktion',
  CREATE_SIBLING_TOPIC: 'Thema hinzufügen',
  CREATE_CHILD_TOPIC: 'Unterthema hinzufügen',
  DELETE_TOPIC: 'Thema löschen',
  EDIT_TOPIC_TEXT: 'Thematext bearbeiten',
  JUST_START_TYPING: 'Eingabe beginnen',
  CANCEL_TEXT_CHANGES: 'Textänderungen abbrechen',
  TOPIC_NAVIGATION: 'Navigieren',
  ARROW_KEYS: 'Pfeiltasten',
  SELECT_MULTIPLE_NODES: 'Wähle Themen markieren',
  UNDO_EDITION: 'Rückgängig',
  REDO_EDITION: 'Wiederholen',
  SELECT_ALL_TOPIC: 'Alles markieren',
  CHANGE_TEXT_BOLD: 'Fett',
  SAVE_CHANGES: 'Speichern',
  CHANGE_TEXT_ITALIC: 'Kursiv',
  DESELECT_ALL_TOPIC: 'Markierung aufheben',
  COLLAPSE_CHILDREN: 'Unterthemen einklappen',
  KEYBOARD_SHORTCUTS_MSG: 'Tastenkombinationen helfen Zeit zu sparen und erlauben die Arbeit nur mit der Tatstatur, s.d. du niemals die Hand von der Tastatur nehmen musst, um die Maus zu bedienen.',
  COPY_AND_PASTE_TOPICS: 'Thema kopieren / einfügen',
  MULTIPLE_LINES: 'Absatz hinzufügen',
  BACK_TO_MAP_LIST: 'Zurück zur Mindmap Liste',
  KEYBOARD_SHOTCUTS: 'Tastenkombinationen',
  PASTE_URL_HERE: 'Füge hier die gewünschte URL ein:',
  CTRL: 'Strg',
  SPACE_BAR: 'Leertaste',
  K_INSERT: 'Insert',
  MOUSE_CLICK: 'Mausklick',
  K_DELETE: 'Entf',
  BACKSPACE: 'Backspace',
};

export default DE;
