/*
 *    Copyright [2021] [wisemapping]
 *
 *   Licensed under WiseMapping Public License, Version 1.0 (the "License").
 *   It is basically the Apache License, Version 2.0 (the "License") plus the
 *   "powered by wisemapping" text requirement on every single page;
 *   you may not use this file except in compliance with the License.
 *   You may obtain a copy of the license at
 *
 *       http://www.wisemapping.org/license
 *
 *   Unless required by applicable law or agreed to in writing, software
 *   distributed under the License is distributed on an "AS IS" BASIS,
 *   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *   See the License for the specific language governing permissions and
 *   limitations under the License.
 */
import Events from '../Events';

export type EventType = 'topicResize' | 'topicMoved' | 'childShrinked' | 'topicConnected' | 'topicAdded' | 'topicRemoved' | 'forceLayout' | 'topicDisconect';
class EventBus extends Events {
  // eslint-disable-next-line no-use-before-define
  static _instance: EventBus = new EventBus();

  static get instance(): EventBus {
    return this._instance;
  }

  fireEvent(type: EventType, eventArgs?: unknown[] | unknown): Events {
    return super.fireEvent(type, eventArgs);
  }

  addEvent(type: EventType, fn?, internal?: boolean): Events {
    return super.addEvent(type, fn, internal);
  }
}

export default EventBus;
