const EN = {
  ZOOM_IN: 'Zoom In',
  ZOOM_OUT: 'Zoom Out',
  TOPIC_SHAPE: 'Topic Shape',
  TOPIC_ADD: 'Add Topic',
  TOPIC_DELETE: 'Delete Topic',
  TOPIC_ICON: 'Add Icon',
  TOPIC_LINK: 'Add Link',
  TOPIC_RELATIONSHIP: 'Relationship',
  TOPIC_COLOR: 'Topic Color',
  TOPIC_BORDER_COLOR: 'Topic Border Color',
  TOPIC_NOTE: 'Add Note',
  FONT_FAMILY: 'Font Type',
  FONT_SIZE: 'Text Size',
  FONT_BOLD: 'Text Bold',
  FONT_ITALIC: 'Text Italic',
  UNDO: 'Undo',
  REDO: 'Redo',
  INSERT: 'Insert',
  SAVE: 'Save',
  NOTE: 'Note',
  ADD_TOPIC: 'Add Topic',
  LOADING: 'Loading ...',
  EXPORT: 'Export',
  PRINT: 'Print',
  PUBLISH: 'Publish',
  COLLABORATE: 'Share',
  HISTORY: 'History',
  DISCARD_CHANGES: 'Discard Changes',
  FONT_COLOR: 'Text Color',
  SAVING: 'Saving ...',
  SAVE_COMPLETE: 'Save Complete',
  ZOOM_IN_ERROR: 'Zoom too high.',
  ZOOM_ERROR: 'No more zoom can be applied.',
  ONLY_ONE_TOPIC_MUST_BE_SELECTED: 'Could not create a topic. Only one topic must be selected.',
  ONE_TOPIC_MUST_BE_SELECTED: 'Could not create a topic. One topic must be selected.',
  ONLY_ONE_TOPIC_MUST_BE_SELECTED_COLLAPSE: 'Children can not be collapsed. One topic must be selected.',
  SAVE_COULD_NOT_BE_COMPLETED: 'Save could not be completed, please try again latter.',
  UNEXPECTED_ERROR_LOADING: "We're sorry, an unexpected error has occurred.\nTry again reloading the editor.If the problem persists, contact us to support@wisemapping.com.",
  MAIN_TOPIC: 'Main Topic',
  SUB_TOPIC: 'Sub Topic',
  ISOLATED_TOPIC: 'Isolated Topic',
  CENTRAL_TOPIC: 'Central Topic',
  SHORTCUTS: 'Keyboard Shortcuts',
  ENTITIES_COULD_NOT_BE_DELETED: 'Could not delete topic or relation. At least one map entity must be selected.',
  AT_LEAST_ONE_TOPIC_MUST_BE_SELECTED: 'At least one topic must be selected.',
  CLIPBOARD_IS_EMPTY: 'Nothing to copy. Clipboard is empty.',
  CENTRAL_TOPIC_CAN_NOT_BE_DELETED: 'Central topic can not be deleted.',
  RELATIONSHIP_COULD_NOT_BE_CREATED: 'Relationship could not be created. A parent relationship topic must be selected first.',
  SELECTION_COPIED_TO_CLIPBOARD: 'Topics copied to the clipboard',
  WRITE_YOUR_TEXT_HERE: 'Write your note here ...',
  REMOVE: 'Remove',
  ACCEPT: 'Accept',
  CANCEL: 'Cancel',
  LINK: 'Link',
  OPEN_LINK: 'Open URL',
  SESSION_EXPIRED: 'Your session has expired, please log-in again.',
  URL_ERROR: 'URL not valid',
  ACTION: 'Action',
  CREATE_SIBLING_TOPIC: 'Create Sibling Topic',
  CREATE_CHILD_TOPIC: 'Create Child Topic',
  DELETE_TOPIC: 'Delete Topic',
  EDIT_TOPIC_TEXT: 'Edit Topic Text',
  JUST_START_TYPING: 'Just start typing',
  CANCEL_TEXT_CHANGES: 'Cancel Text Changes',
  TOPIC_NAVIGATION: 'Topic Navigation',
  ARROW_KEYS: 'Arrow Keys',
  SELECT_MULTIPLE_NODES: 'Select Multiple Nodes',
  UNDO_EDITION: 'Undo Edition',
  REDO_EDITION: 'Redo Edition',
  SELECT_ALL_TOPIC: 'Select All Topic',
  CHANGE_TEXT_BOLD: 'Change Text Bold Type',
  SAVE_CHANGES: 'Save Changes',
  CHANGE_TEXT_ITALIC: 'Change Text Italic',
  DESELECT_ALL_TOPIC: 'Deselect All Topic',
  COLLAPSE_CHILDREN: 'Collapse Children',
  KEYBOARD_SHORTCUTS_MSG: 'Keyboard shortcuts can help you save time by allowing you to never take your hands off the keyboard to use the mouse.',
  COPY_AND_PASTE_TOPICS: 'Copy and Paste Topics',
  MULTIPLE_LINES: 'Add multiple text lines',
  BACK_TO_MAP_LIST: 'Back to Maps List',
  KEYBOARD_SHOTCUTS: 'Keyboard Shorcuts',
  PASTE_URL_HERE: 'Вставьте нужный URL-адрес здесь:',
  CTRL: 'Ctrl',
  SPACE_BAR: 'Space Bar',
  K_INSERT: 'Insert',
  MOUSE_CLICK: 'Mouse Click',
  K_DELETE: 'Delete',
  BACKSPACE: 'Backspace',
};

export default EN;
